@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navLink {
    @apply hover:text-dark-orange hover:scale-95 opacity-100;
  }

  .about-p {
    @apply mt-10 text-xl xl:my-14 lg:text-2xl text-center 2xl:text-justify font-semibold leading-relaxed tracking-wide font-playfair;
  }

  .project-p {
    @apply mt-3 lg:max-w-[65%] text-xl lg:text-2xl font-semibold leading-relaxed tracking-wide font-playfair;
  }

  .icon {
    @apply max-w-[5rem] md:max-w-[4.5rem] lg:max-w-[5rem] 2xl:max-w-[5.25rem];
  }

  .project-icon {
    @apply text-[30px] py-0 px-[5px] hover:scale-105;
  }

  .project-a {
    @apply cursor-pointer no-underline;
  }

  .project-tab {
    @apply px-2 py-1 rounded font-playfair tracking-wider border-2 border-transparent;
  }

  .project-button {
    @apply px-2 py-1 rounded text-white font-playfair tracking-wider text-base;
  }

  .text-shadow {
    @apply drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.4)];
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  width: 100%;
  padding: 5px;
  background: #000;
  border-bottom: 2px solid gray;
  caret-color: white;
  color: white;
  font-family: "Playfair Display", serif;
  letter-spacing: 0.025em;
}

input:focus {
  border-color: white;
  outline: none;
}

ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul li {
  display: inline;
  font-size: 1.1rem;
  margin-right: 1.5rem;
}

@media (max-height: 420px) {
  .my-name {
    display: none;
  }
}
